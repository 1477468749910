exports.components = {
  "component---src-components-templates-blogs-template-tsx": () => import("./../../../src/components/templates/BlogsTemplate.tsx" /* webpackChunkName: "component---src-components-templates-blogs-template-tsx" */),
  "component---src-components-templates-works-template-tsx": () => import("./../../../src/components/templates/WorksTemplate.tsx" /* webpackChunkName: "component---src-components-templates-works-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-service-app-tsx": () => import("./../../../src/pages/service/app.tsx" /* webpackChunkName: "component---src-pages-service-app-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-service-mvp-tsx": () => import("./../../../src/pages/service/mvp.tsx" /* webpackChunkName: "component---src-pages-service-mvp-tsx" */),
  "component---src-pages-service-repair-tsx": () => import("./../../../src/pages/service/repair.tsx" /* webpackChunkName: "component---src-pages-service-repair-tsx" */),
  "component---src-pages-service-site-tsx": () => import("./../../../src/pages/service/site.tsx" /* webpackChunkName: "component---src-pages-service-site-tsx" */),
  "component---src-pages-service-system-tsx": () => import("./../../../src/pages/service/system.tsx" /* webpackChunkName: "component---src-pages-service-system-tsx" */),
  "component---src-pages-works-index-tsx": () => import("./../../../src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */)
}

